import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo (['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule),
    ...canActivate(redirectLoggedInToHome)
  },
  {
    path: 'ficha',
    loadChildren: () => import('./ficha/ficha.module').then( m => m.FichaPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  }, 
  {
    path: 'dados-basicos',
    loadChildren: () => import('./dados-basicos/dados-basicos.module').then( m => m.DadosBasicosPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'pop-over-modal',
    loadChildren: () => import('./componentes/pop-over-modal/pop-over-modal.module').then( m => m.PopOverModalPageModule)
  },
  {
    path: 'modal-atributo',
    loadChildren: () => import('./componentes/modal-atributo/modal-atributo.module').then( m => m.ModalAtributoPageModule)
  },
  {
    path: 'modal-compartilhamento',
    loadChildren: () => import('./componentes/modal-compartilhamento/modal-compartilhamento.module').then( m => m.ModalCompartilhamentoPageModule)
  },
  {
    path: 'modal-add',
    loadChildren: () => import('./componentes/modal-add/modal-add.module').then( m => m.ModalAddPageModule)
  },
  {
    path: 'modal-resistencia',
    loadChildren: () => import('./componentes/modal-resistencia/modal-resistencia.module').then( m => m.ModalResistenciaPageModule)
  },
  {
    path: 'modal-cadastro',
    loadChildren: () => import('./componentes/modal-cadastro/modal-cadastro.module').then( m => m.ModalCadastroPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
